<template>
  <v-container>
    <v-img :src="require('../assets/logo_wr.png')" class="my-3" contain height="200" />

    <h1 class="display-2 font-weight-bold mb-3">Wagon Dashboard</h1>

    <p class="subheading font-weight-regular">
      Tableau de bord assisté de suivi des transports de marchandises et de wagons liés à <a href="https://www.wagonreport.com" target="_blank">Wagon Report</a>
    </p>

    <div class="d-flex flex-row justify-center flex-wrap mx-auto my-4">
      
      <v-card class="ma-2 py-6" width="400">
        <v-icon color="primary" dark x-large>mdi-train-car-container</v-icon>
        <v-icon color="primary" dark x-large>mdi-train-car-container</v-icon>
        <v-icon color="primary" dark x-large>mdi-train-car-container</v-icon>
        <h1 style="text-align: center" class="mb-5">
          Suivi des conteneurs
        </h1>
        <v-btn color="primary" outlined text to="/containers" v-if="$USER_CONTAINERVIEW">explorer</v-btn>
        <v-btn color="primary" outlined text to="/login" v-if="!$USER_CONTAINERVIEW">se connecter</v-btn>
      </v-card>

      <v-card class="ma-2 py-6" width="400">
        <v-icon color="success" dark x-large>mdi-bottle-soda-classic-outline</v-icon>
        <v-icon color="success" dark x-large>mdi-bottle-soda-classic-outline</v-icon>
        <v-icon color="success" dark x-large>mdi-bottle-soda-classic-outline</v-icon>
        <h1 style="text-align: center" class="mb-5">
          Suivi des marchandises
        </h1>
        <v-btn color="success" outlined text to="/purchaseorder" v-if="$USER_SUIVITRAIN">explorer</v-btn>
        <v-btn color="primary" outlined text to="/login" v-if="!$USER_SUIVITRAIN">se connecter</v-btn>
      </v-card>

      <v-card class="ma-2 py-6" width="400">
        <v-icon color="success" dark x-large>mdi-bottle-soda-classic-outline</v-icon>
        <v-icon color="success" dark x-large>mdi-bottle-soda-classic-outline</v-icon>
        <v-icon color="success" dark x-large>mdi-bottle-soda-classic-outline</v-icon>
        <h1 style="text-align: center" class="mb-5">
          Suivi du pool wagons
        </h1>
        <v-btn color="success" outlined text to="/wagons" v-if="$USER_POOLWAGONS">explorer</v-btn>
        <v-btn color="primary" outlined text to="/login" v-if="!$USER_POOLWAGONS">se connecter</v-btn>
      </v-card>

      <v-card class="ma-2 py-6" width="400">
        <v-icon color="success" dark x-large>mdi-finance</v-icon>
        <v-icon color="success" dark x-large>mdi-chart-bar</v-icon>
        <v-icon color="success" dark x-large>mdi-chart-line</v-icon>
        <h1 style="text-align: center" class="mb-5">
          Kpi
        </h1>
        <v-btn color="success" outlined text to="/kpi" v-if="$USER_KPI">explorer</v-btn>
        <v-btn color="primary" outlined text to="/kpi" v-if="!$USER_KPI">se connecter</v-btn>
      </v-card>

    </div>

    <p><i>Une application réalisée et hébergée par</i></p>
    <v-img
      :src="require('../assets/logo_linaware.png')"
      style="margin-top: -10px"
      contain
      height="100"
    />
    <div><a href="https://www.linaware.eu" target="_blank">linaware.eu</a></div>

  </v-container>
</template>

<script>
  import Vue from 'vue'
  import Vuex from 'vuex'
 // import { createEditor } from 'vueditor'
  import 'vueditor/dist/style/vueditor.min.css'
  
  Vue.use(Vuex);


  export default
  {
    name: 'HelloWorld',

    components: {},

    data: () => ({
    }),

    mounted() {
      /*
       createEditor('#editorContainer', {
        toolbar: [
          'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize', 'foreColor', 'backColor', 
        ],
        uploadUrl: '',
        id: '',
        classList: []
      });*/

    },

    methods: {
    }
  }
</script>
